<template>
    <div>
        hellod
    </div>
</template>
<script>
export default {
    mounted() {
        console.log(this.$route.hash)
    },
}
</script>